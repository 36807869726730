import React from "react";
import { Container, Typography, Grid, Card, CardContent, CardMedia, Link, IconButton } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import RoomIcon from "@mui/icons-material/Room";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => (
  <div style={{ color: "red", fontSize: "24px" }}>
    <RoomIcon />
  </div>
);

function ContactUsPage() {
  return (
    <Container maxWidth="md" sx={{ textAlign: "center", paddingTop: "50px" }}>
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ maxWidth: 400, margin: "auto" }}>
            <CardMedia component="img" height="auto" src="/static/logo.png" alt="Logo" />
            <CardContent>
              <Typography variant="body1" gutterBottom>
                <PhoneIcon /> <Link href="tel:+1234567890">0998-592-9640</Link>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <RoomIcon /> Medrano, Calapan City, Philippines
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: 400 }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyA0l5mfMyUWnKDbnYAuTj-h5kBTjAhD99U" }}
              defaultCenter={{ lat: 13.411871, lng: 121.182171 }}
              defaultZoom={16}
            >
              <AnyReactComponent lat={13.411871} lng={121.182171} text="Marker" />
            </GoogleMapReact>
          </Card>
        </Grid>
      </Grid>
      <div style={{ marginTop: "20px" }}>
        <IconButton href="https://facebook.com">
          <FacebookIcon />
        </IconButton>
        <IconButton href="https://twitter.com">
          <TwitterIcon />
        </IconButton>
        <IconButton href="https://instagram.com">
          <InstagramIcon />
        </IconButton>
      </div>
    </Container>
  );
}

export default ContactUsPage;
