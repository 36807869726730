import { Icon } from '@iconify/react';
import creditcardtwotone from '@iconify/icons-ant-design/credit-card-twotone';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
// import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(3, 0),
  color: "black",
  backgroundColor: "whitesmoke"
}));

// const IconWrapperStyle = styled('div')(({ theme }) => ({
//   margin: 'auto',
//   display: 'flex',
//   borderRadius: '50%',
//   alignItems: 'center',
//   width: theme.spacing(10),
//   height: theme.spacing(10),
//   justifyContent: 'center',
//   marginBottom: theme.spacing(3),
//   color: theme.palette.error.dark,
//   backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
//     theme.palette.info.dark,
//     0.24
//   )} 100%)`
// }));
const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: "blue",
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`,
  
  // Default styles (for mobile)
  width: theme.spacing(8),
  height: theme.spacing(8),
  marginBottom: theme.spacing(2),

  // Responsive styles for tablet, desktop, and larger screens
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(10),  // Adjust for tablet screens
    height: theme.spacing(10),
    marginBottom: theme.spacing(3),
  },
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(10),  // Adjust for desktop screens
    height: theme.spacing(10),
    marginBottom: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    width: theme.spacing(10),  // Adjust for large desktop screens
    height: theme.spacing(10),
    marginBottom: theme.spacing(3),
  },
}));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  // Default styles for mobile
  fontSize: '0.7rem', // Smaller size for mobile

  // Responsive font sizes for different breakpoints
  [theme.breakpoints.up('sm')]: {
    fontSize: '2rem',  // Larger size for tablet screens
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',  // Larger size for desktop screens
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2rem',  // Even larger size for large desktop screens
  },
}));


// ----------------------------------------------------------------------


export default function HMO() {
  return (
    <RootStyle>
      {/* <IconWrapperStyle>
        <Icon icon={creditcardtwotone} width={35} height={35} />
      </IconWrapperStyle> */}
      <div><img src="/static/cardImages/accrditd-hmo.png" alt="medicareplus logo" /></div>
      <ResponsiveTypography variant="h3">Accredited HMO </ResponsiveTypography>

      {/* <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Bug Reports
      </Typography> */}
    </RootStyle>
  );
}
